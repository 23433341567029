<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      class="alert-modal"
      content-class="modal-register"
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">แก้ไขข้อมูลลูกค้า</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="d-block">
        <b-tabs>
          <b-tab title="1. ข้อมูลส่วนตัว" active>
            <div class="p-3 register-box">
              <b-row>
                <b-col md="6">
                  <InputText
                    textFloat="ชื่อ"
                    placeholder="ชื่อ"
                    name="firstname"
                    v-model="form.firstname"
                    className="mb-2"
                    isRequired
                    :isValidate="$v.form.firstname.$error"
                    :v="$v.form.firstname"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="นามสกุล"
                    placeholder="นามสกุล"
                    name="lastname"
                    v-model="form.lastname"
                    className="mb-2"
                    isRequired
                    :isValidate="$v.form.lastname.$error"
                    :v="$v.form.lastname"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="Name"
                    placeholder="Name"
                    name="firstname_en"
                    v-model="form.firstname_en"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="Surname"
                    placeholder="Surname"
                    name="lastname_en"
                    v-model="form.lastname_en"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputDatePickerFilter
                    textFloat="วันเกิด"
                    name="birthday"
                    ref="birthday"
                    placeholder="วันเกิด"
                    v-model="form.birthday"
                    @input="(val) => form.birthday = val "
                    :maxDatetime="now"
                    className="mb-2"
                    :isValidate="$v.form.birthday.$error"
                    :v="$v.form.birthday"
                    isRequired
                  />
                </b-col>
                <b-col md="6">
                  <InputRadioGroup
                    textFloat="เพศ"
                    placeholder="เพศ"
                    id="gender"
                    name="gender"
                    v-model="form.gender"
                    className="mb-2"
                    isRequired
                    :options="genderList"
                    @onDataChange="(val) => form.gender = val "
                    :isValidate="$v.form.gender.$error"
                    :v="$v.form.gender"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="E-mail"
                    placeholder="E-mail"
                    name="email"
                    v-model="form.email"
                    className="mb-2"
                    :isValidate="$v.form.email.$error"
                    :v="$v.form.email"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="เบอร์โทรศัพท์"
                    placeholder="เบอร์โทรศัพท์"
                    name="telephone"
                    v-model="form.telephone"
                    className="mb-2"
                    isRequired
                    :isValidate="$v.form.telephone.$error"
                    :v="$v.form.telephone"
                    @onKeypress="isNumber($event)"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="Line ID"
                    placeholder="Line ID"
                    name="line_id"
                    v-model="form.line_id"
                    className="mb-2"
                  />
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="2. ข้อมูลที่อยู่">
            <div class="p-3 register-box">
              <b-row>
                <b-col md="6">
                  <InputText
                    textFloat="บ้านเลขที่"
                    placeholder="บ้านเลขที่"
                    name="home_address"
                    v-model="form.home_address"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="อาคาร"
                    placeholder="อาคาร"
                    name="town"
                    v-model="form.town"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="ซอย"
                    placeholder="ซอย"
                    name="alley"
                    v-model="form.alley"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="ถนน"
                    placeholder="ถนน"
                    name="road"
                    v-model="form.road"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="แขวง"
                    placeholder="แขวง"
                    name="subdistrict"
                    v-model="form.subdistrict"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="เขต"
                    placeholder="เขต"
                    name="district"
                    v-model="form.district"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="จังหวัด"
                    placeholder="จังหวัด"
                    name="province"
                    v-model="form.province"
                    className="mb-2"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    textFloat="รหัสไปรษณีย์"
                    placeholder="รหัสไปรษณีย์"
                    name="zip_code"
                    v-model="form.zip_code"
                    className="mb-2"
                    :isValidate="$v.form.zip_code.$error"
                    :v="$v.form.zip_code"
                    @onKeypress="isNumber($event)"
                  />
                </b-col>
              </b-row></div
          ></b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            แก้ไข
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    InputDatePickerFilter,
    InputRadioGroup
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        id: 0,
        firstname: "",
        lastname: "",
        firstname_en: "",
        lastname_en: "",
        gender: "",
        birthday: "",
        telephone: "",
        home_address: "",
        alley: "",
        road: "",
        town: "",
        zip_code: "",
        district: "",
        subdistrict: "",
        province: "",
        branch_id: this.branchId,
        email: "",
        password: "",
        nickname: "",
        t_datetime: "",
        line_id: "",
        the_one_card_member: 0,
        line_ref: "",
        is_ba: 1,
        is_consent: 0,
        is_privacy_policy: 0,
        user_guid: ""
      },
      now: null,
      genderList: [
        { text: 'ชาย', value: 'Male' },
        { text: 'หญิง', value: 'Female' },
        { text: 'ไม่ระบุเพศ', value: 'N/A' },
      ]
    };
  },
  validations() {
    return {
      form: {
        firstname: { required },
        lastname: { required },
        birthday: { 
          required, 
          maxValueToday: (value) => {
            let dateTime = this.$moment(value, 'YYYY-MM-DDTHH:mm:ss').unix();
            let now = this.$moment().unix();
            return dateTime <= now;
          },
        },
        gender: { required },
        telephone: { required, minLength: minLength(9), maxLength: maxLength(10) },
        email: { email },
        zip_code: { minLength: minLength(5), maxLength: maxLength(5) },
      },
    };
  },
  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  methods: {
    async show(user) {
      this.$v.form.$reset();
      this.form = user
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {...this.form};
      body.telephone = body.telephone.trim();
      body.email = body.email.trim();

      await this.axios
        .post(`${this.$baseUrl}/customer/editdata`, body)
        .then((data) => {
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$emit("success");
            this.isLoading = false;
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .modal-register {
  .btn-link {
    border-color: transparent !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    padding: 0;
  }
  .register-box {
    background: #f7f7f7;
    border: 1px solid #d8dbe0;
  }
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 1px solid white !important;
      border-bottom: 3px solid white !important;
      font-weight: 600;
      background: white !important;
      color: #333 !important;
    }
    .nav-link.active {
      border: 1px solid var(--secondary-color) !important;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
  .title {
    color: #333 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    width: 100%;
    position: relative;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}
</style>
