<template>
  <div :class="['div-input', className]">
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" class="title">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div>
        <b-form-group class="form-group-custom" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            :id="id"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            :name="name"
            :valueField="valueField"
            :textField="textField"
            @change="onDataChange"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="textWarning"> {{ textWarning }}</span>
      <span class="text-error" v-else-if="v.required == false"
        >กรุณากรอกข้อมูล</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String, Number]
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    options: {
      required: true,
      type: Array
    },
    valueField: {
      required: false,
      type: String
    },
    textField: {
      required: false,
      type: String
    },
    textWarning: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      passwordType: 'password',
      selected: this.value
    };
  },
  watch: {
    value: {
      handler() {
        this.selected = this.value;
      },
      immediate: true
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit('onDataChange', event);
    }
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  position: relative;
  white-space: nowrap;
}
.div-input .title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  position: relative;
}
.input-custom {
  padding: 0px;
}
.input-border-0 .input-custom input {
  border: 0 !important;
}
// .input-custom input {
//   color: #404040;
//   border: 1px solid #bcbcbc !important;
//   border-radius: 10px;
//   padding: 10px;
// }
// .input-custom > input:focus {
//   border: 1px solid var(--primary-color) !important;
// }
// .input-custom.error > input {
//   border-color: red !important;
// }
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
::v-deep .custom-control-label span {
  color: #333 !important;
  font-size: 14px;
}
.form-group-custom {
  margin-bottom: 0;
  margin-top: 5px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before,
::v-deep
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
::v-deep
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--primary-color);
}
::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}
</style>
